<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import {mapActions, mapGetters} from "vuex";
import paymentRequests from "@/store/user/paymentRequests";
import store from "@/store";
import axios from "axios";
import user from "../../store/user/user";

export default {
  name: "balance",
  components: {TitlteH1},
  data() {
    return {
      headers: [
        {text: "ID", value: "id", sortable: false},
        {text: "Сумма", value: "amount", sortable: false},
        {text: "Статус", value: "status", sortable: false},
        {text: "Данные", value: "info", sortable: false},
        {text: "Дата", value: "date", sortable: false},
      ],
      // Правила валидации
      rules: {
        requiredRule: v => !!v || 'Обязательное поле',
        minLengthRule: (minLength, msg) => v => (v && v.length >= minLength) || msg.replace('{0}', minLength),
        minValueRule: (sum, msg) => v => {
          const numericValue = v ? parseFloat(v.replace(/\D/g, '')) : 0;
          return (numericValue >= sum) || msg.replace('{0}', sum);
        },
        maxValueRule: (sum, msg) => v => {
          const numericValue = v ? parseFloat(v.replace(/\D/g, '')) : 0;
          return (numericValue <= sum) || msg.replace('{0}', sum);
        },

        requiredRulePhone: v => (this.isPhoneNumberTab ? !!v : true) || 'Обязательное поле',
        requiredRuleCard: v => (this.isBankCardTab ? !!v : true) || 'Обязательное поле',
      },
      dialog: false,
      pendingRequest: 0.00,
      tab: 0,
      amount: undefined,
      firstName: undefined,
      middleName: undefined,
      lastName: undefined,
      city: undefined,
      phone: undefined,
      bankName: undefined,
      card: undefined,
    }
  },
  computed: {
    user() {
      return user
    },
    isPhoneNumberTab() {
      return this.tab === 0;
    },
    isBankCardTab() {
      return this.tab === 1;
    },
    phoneValidationRule() {
      return this.isPhoneNumberTab ? [this.rules.requiredRulePhone, this.rules.minLengthRule(16, 'Проверьте введенный номер на корректность')] : [];
    },
    bankNameValidationRule() {
      return this.isPhoneNumberTab ? [this.rules.requiredRulePhone, this.rules.minLengthRule(2, 'Минимальная длина {0} символа')] : [];
    },
    cardValidationRule() {
      return this.isBankCardTab ? [this.rules.requiredRuleCard, this.rules.minLengthRule(19, 'Проверьте корректность номера карты')] : [];
    },

    paymentRequests() {
      return paymentRequests
    },
    ...mapGetters(['user', 'URL', 'token', 'paymentRequests']),
    // Вычисляемое свойство для проверки наличия элементов с status === 0
    hasPendingRequest() {
      this.pendingRequest = this.paymentRequests.find(item => item.status === 1);
      return this.paymentRequests.some(item => item.status === 1);
    },

    // Вычисляемое свойство для форматирования данных перед отображением в таблице
    formattedPaymentRequests() {
      return this.paymentRequests.map((item) => {
        // Условие для определения, какую дату отображать
        const dateToShow =
          new Date(item.createdAt) >= new Date(item.updatedAt)
            ? item.createdAt
            : item.updatedAt;

        // Преобразуем дату в строку с учетом часового пояса браузера
        const formattedDate = new Date(dateToShow).toLocaleString();

        // Возвращаем объект с данными, включая новое значение "date"
        return {
          ...item,
          date: formattedDate,
        };
      })
    },
    // Вычисляемое свойство для преобразования значения status в текст
    statusText() {
      const statusMap = {
        1: "Ожидание",
        2: "Отменено",
        3: "Отклонено",
        4: "Обработано",
      };
      return (status) => statusMap[status] || status;
    },
  },
  mounted() {
    this.fetchPaymentRequests();
  },
  methods: {
    ...mapActions(["fetchPaymentRequests"]),
    openDialog() {
      this.dialog = true; // Открывает диалоговое окно при нажатии на кнопку "Вывод"
    },

    // Классы для строчек
    getStatusClass(status) {
      return {
        'status-pending': status === 1,
        'status-cancelled': status === 2,
        'status-rejected': status === 3,
        'status-completed': status === 4,
      };
    },

    // Для склеивании массива с данными
    formatInfo(infoArray) {
      return infoArray.join('<br>');
    },

    // Для отправки формы запроса
    submitForm() {
      const isValid = this.$refs.withdrawForm.validate();
      if (isValid) {
        const requestData = {
          amount: parseInt(this.amount),
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          city: this.city,
          phone: this.phone,
          bankName: this.bankName,
          card: this.card,
          type: this.tab
        };

        // console.log(requestData);
        // Отправляем POST-запрос с использованием Axios
        axios.post(store.getters.URL + '/api/photographer/payment_request', requestData, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
          // console.log(response.data);

          // Добавляем новый запрос в начало массива через мутацию
          this.$store.commit('SET_PAYMENT_REQUESTS', [response.data, ...this.paymentRequests]);

          this.dialog = false;
        }).catch(error => {
          // Обработка ошибки
          console.error('Error during POST request:', error);
        });
      }
    },
  }
}
</script>

<template>
  <div class="view-page">
    <TitlteH1 title="История начислений" class="mb-2 mb-xl-8"/>

    <div class="d-flex flex-row align-baseline  mb-6 bg-surface-variant">
      <div class="">
        <strong>Баланс:</strong> {{ user.balance }} ₽
      </div>
      <v-btn variant="plain" @click="openDialog" :disabled="hasPendingRequest" icon>
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="formattedPaymentRequests"
      hide-default-footer
      class="elevation-1"
      density="compact"
      item-key="id"
    >
      <template v-slot:item.amount="{ item }">
        {{ parseInt(item.amount) }} ₽
      </template>

      <template v-slot:item.status="{ item }">
        <div :class="getStatusClass(item.status)">
          {{ statusText(item.status) }}
        </div>
      </template>

      <template v-slot:item.info="{ item }">
        <div v-html="formatInfo(item.info)"></div>
      </template>
    </v-data-table>


    <!-- Диалоговое окно -->
    <v-dialog v-model="dialog" persistent width="768px">
      <v-card>
        <v-form ref="withdrawForm">
          <v-card-title>
            <span class="text-h5">Запрос на вывод</span>
          </v-card-title>
          <v-card-text>

            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="lastName"
                    label="Фамилия*"
                    :rules="[rules.requiredRule, rules.minLengthRule(2, 'Минимальная длина {0} символа')]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="firstName"
                    label="Имя*"
                    :rules="[rules.requiredRule, rules.minLengthRule(2, 'Минимальная длина {0} символа')]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="middleName"
                    label="Отчество*"
                    :rules="[rules.requiredRule, rules.minLengthRule(2, 'Минимальная длина {0} символа')]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="city"
                    label="Город*"
                    :rules="[rules.requiredRule, rules.minLengthRule(2, 'Минимальная длина {0} символа')]">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="amount"
                    label="Сумма для вывода"
                    v-maska="'#####'"
                    :rules="[rules.requiredRule, rules.minValueRule(200, 'Минимальная сумма для вывода - 200 рублей'), rules.maxValueRule(user.balance, 'Сумма не должна превышать баланс')]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-tabs v-model="tab">
              <v-tab :key="0">По номеру телефона</v-tab>
              <v-tab :key="1">По номеру карты</v-tab>

              <v-tab-item :key="0">
                <v-container>
                  <!-- Поля для номера телефона и названия банка -->
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model.trim="phone"
                        v-maska="'+7(###)###-##-##'"
                        placeholder="+7(###)###-##-##"
                        :rules="phoneValidationRule"
                        label="Телефон*"
                        hint="Номер телефона, к которому привязана карта, по этому номеру будет осуществляться перевод."
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.trim="bankName"
                        label="Название банка*"
                        :rules="bankNameValidationRule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="1">
                <v-container>
                  <!-- Поля для номера карты -->
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.trim="card"
                        label="Номер банковской карты*"
                        v-maska="'#### #### #### ####'"
                        placeholder="#### #### #### ####"
                        :rules="cardValidationRule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>

            <small><strong>*Поля обязательны к заполнению.</strong></small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" plain @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn color="blue-darken-1" plain @click="submitForm">
              Запросить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>

</template>

<style scoped lang="scss">
.status-pending {
  color: orange; // Цвет для статуса "Ожидание"
}

.status-cancelled {
  color: red; // Цвет для статуса "Отменено"
}

.status-rejected {
  color: red; // Цвет для статуса "Отклонено"
}

.status-completed {
  color: green; // Цвет для статуса "Выведено"
}
</style>
