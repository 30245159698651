<template>
  <div class="view-page">
    <TitlteH1 title="Создать продажу" class="mb-2 mb-xl-8"/>
    <StepperSale/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import TitlteH1 from "@/components/typography/TitlteH1";
import StepperSale from "@/views/sale/StepperSale";

export default {
  name: "NewDeal",
  components: {StepperSale, TitlteH1},
  methods: {
    ...mapActions(['fetchLocations'])
  },
  mounted() {
    this.fetchLocations()
  }
}
</script>

<style lang="scss" scoped>

</style>