<template>
  <v-btn
    icon
    class="burger justify-start"
    @click="$emit('click')"
    :ripple="false"
    x-large
  >
    <v-icon>
      mdi-menu
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BurgerButton"
}
</script>

<style lang="scss" scoped>
.burger {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
}
</style>
