<template>
  <div class="view-page">
    <TitlteH1 title="Загрузить фото" class="mb-2 mb-xl-8"/>
    <Stepper/>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import TitlteH1 from "@/components/typography/TitlteH1.vue";
import Stepper from "@/views/free/Stepper.vue";

export default {
  name: "Free",
  components: {Stepper, TitlteH1},
  methods: {
    ...mapActions(['fetchLocations'])
  },
  mounted() {
    this.fetchLocations()
  }
}
</script>

<style lang="scss" scoped>

</style>
