import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import maska from 'maska'
import '@mdi/font/css/materialdesignicons.css';
import './config/webfontloader.js'

import './scss/main.scss' // Изменил наименование файла, чтобы он правильно импортировался

Vue.use(maska)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
