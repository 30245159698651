<template>
  <div class="drawer">
    <div class="user">
      <div class="user-photo">
        <v-img src="@/assets/img/user.png"/>
      </div>
      <div class="user-name" v-if="$store.getters.user">
        {{ $store.getters.user.email }}
        <span v-if="$store.getters.user.firstName">{{ $store.getters.user.firstName }}</span>
        <span v-else>Фотограф</span>
      </div>
    </div>
    <v-list
      nav
    >
      <v-list-item
        v-for="link in links"
        :key="link.title"
        link
        :class="[{'drawer-link-active': $route.name === link.route}]"
        :to="link.route"
      >
        <v-list-item-icon class="mr-2">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ link.title }}
          </v-list-item-title>
        </v-list-item-content>

        <!-- Добавленный код для второй кнопки -->
        <template v-if="link.route === 'photos'">
          <v-btn link to="/profile" x-small color="primary">Добавить</v-btn>
        </template>

        <template v-if="link.route === 'deals'">
          <v-btn link to="/sale" x-small color="primary">Добавить</v-btn>
        </template>
      </v-list-item>

      <v-list-item
        link
        :class="[{'drawer-link-active': $route.name === 'notifications'}]"
        to="/notifications"
      >
        <v-list-item-icon class="mr-2">
          <v-badge
            :content="notifications.length"
            :value="notifications.length"
            color="error"
            overlap
            transition="fade"
          >
            <v-icon>mdi-bell-plus-outline</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Уведомления
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        :class="[{'drawer-link-active': $route.name === 'settings'}]"
        to="/settings"
      >
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Настройки профиля
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

<!--      <v-btn link to="/sale" block class="mt-6" small color="primary">Создать продажу</v-btn>-->

      <v-list-item key="logout" @click="logoutUser" class="mt-6">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Drawer",
  data() {
    return {
      links: [
        {title: 'Мой баланс', icon: 'mdi-wallet', route: 'balance'},
        {title: 'Продажи онлайн', icon: 'mdi-cart-variant', route: 'history'},
        {title: 'Фото онлайн', icon: 'mdi-cards-heart-outline', route: 'photos'},
        {title: 'Продажи на месте', icon: 'mdi-qrcode-scan', route: 'deals'},
        {title: 'Бесплатные фото', icon: 'mdi-image-multiple', route: 'free'},
      ]
    }
  },
  methods: {
    ...mapActions(['logoutUser'])
  },
  computed: {
    ...mapGetters(['notifications'])
  },
  mounted() {
    // console.log(this.notifications)
  }
}
</script>

<style lang="scss" scoped>
.drawer-link-active {
  background: rgba(121, 93, 218, .2);
}

.theme--light.v-navigation-drawer {
  box-shadow: 0 4px 40px rgba(77, 93, 113, 0.1);
}

.user {
  padding: 48px 8px 34px;
  display: flex;
  gap: 7px;
  align-items: center;
}

.user-photo {
  width: 64px;
  height: 64px;
}

.user-name {
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #222222;

  span {
    font-weight: 400;
    font-size: 16px;
    color: #A9A9A9;
    display: block;
    margin-top: 10px;
  }
}

.new-deal {
  color: #fff !important;
}

</style>
