import store from "@/store/index";
import axios from "axios";
import router from "@/router";

export default {
  state: {
    paymentRequests: [],
  },

  getters: {
    paymentRequests: (state) => state.paymentRequests,
  },

  mutations: {
    SET_PAYMENT_REQUESTS(state, array) {
      state.paymentRequests = array;
    },
  },

  actions: {
    async fetchPaymentRequests({commit}) {
      try {
        const response = await axios({
          baseURL: store.getters.URL + "/api/photographer/payment_requests",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${store.getters.token}`,
          },
          method: "GET",
        });

        await commit("SET_PAYMENT_REQUESTS", response.data);
      } catch (e) {
        console.log(e)
        if (e.response.data.message === 'Expired JWT Token' || e.response.data.message === 'Invalid credentials.') {
          await store.dispatch('logoutUser')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      }
    },
  },
};
