<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1"/>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="step > 2"/>
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="step > 3"/>
      <v-divider></v-divider>
      <v-stepper-step step="4" :complete="step > 4"/>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <div class="stepper-title">
              Выберите дату и локацию
            </div>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="nextStep"
        >
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-select :items="locations" item-text="title" item-value="id" :rules="rules.globalRules"
                        label="Выберите локацию" v-model="location"></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-dialog ref="dialog" v-model="datepicker" :return-value.sync="date" persistent width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormatted" label="Укажите дату" readonly v-bind="attrs" v-on="on"
                                :rules="rules.globalRules" :disabled="!location"></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable first-day-of-week="1" :allowed-dates="checkDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datepicker = false">
                    Назад
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    Ок
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-btn :small="mobile" type="submit" color="primary" block>
                Далее
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <div class="stepper-title">
              Выберите фото для загрузки
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <v-file-input ref="fileInput" accept="image/jpeg" label="Выбрать..." multiple counter show-size
                          prepend-icon="mdi-image" v-model="photos" @change="handleFileChange"
                          :error-messages="fileInputErrorMessages"/>
            <v-progress-linear v-if="loading" indeterminate color="primary" class="mt-2 mb-2"
                               height="10"></v-progress-linear>
            <v-progress-linear v-if="uploading" :value="uploadProgress" :error="failedUploads.length" color="primary"
                               height="15" class="mt-2 mb-2">
              <p class="ma-0 pa-0">{{ this.successfulUploads.length }} / {{ this.photos.length }}</p>
            </v-progress-linear>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block outlined @click="step = 1">
              Назад
            </v-btn>
          </v-col>
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block :disabled="!photos || uploading"
                   @click="uploadPhotos">
              Далее
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-row class="justify-center mb-3 my-0">
          <v-col cols="12" lg="4" class="py-0 py-lg-3">
            <div class="option">
              <div class="descr">Дата фотосъемки:</div>
              <div class="descr-2">{{ dateFormatted }}</div>
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="py-0 py-lg-3">
            <div class="option">
              <div class="descr">Выбранная локация:</div>
              <div class="descr-2" v-if="selectedLocation">
                {{ selectedLocation.title }},
                {{ selectedLocation.address }}
              </div>
            </div>
          </v-col>
        </v-row>
        <GalleryBtns @delete="removePhoto" hasDelete :gallery="returnGallery"/>
        <v-row class="justify-center mt-4">
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block outlined @click="step = 2">
              Назад
            </v-btn>
          </v-col>
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block @click="nextStep">
              На модерацию
            </v-btn>
          </v-col>
          <v-col style="opacity: 0" v-if="!disabledLoad" cols="12" lg="2">
            <v-btn :small="mobile" type="submit" color="primary" block v-intersect="next" @click="next" outlined>
              Еще
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="4">
        <div class="final-img">
          <v-img src="@/assets/img/step4.png"/>
        </div>
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <div class="stepper-title">
              Ваши фото успешно загружены
            </div>
          </v-col>
        </v-row>
<!--        <v-row>-->
<!--          <v-col cols="12">-->
<!--            <div class="descr text-center">Процесс модерации вы можете отследить на странице «Фото онлайн»</div>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row class="justify-center mt-4">
          <v-col cols="12" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block outlined @click="uploadAgain">
              Загрузить еще
            </v-btn>
          </v-col>
<!--          <v-col cols="12" lg="6">-->
<!--            <v-btn :small="mobile" type="submit" color="primary" block @click="$router.push({name: 'photos'})">-->
<!--              К моим фото-->
<!--            </v-btn>-->
<!--          </v-col>-->
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import Gallery from "@/components/gallery/GalleryStepper";
import GalleryStepper from "@/components/gallery/GalleryStepper";
import VGallery from "@/components/gallery/VGallery";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";
import CryptoJS from "crypto-js";

export default {
  name: "Stepper",
  components: {GalleryBtns, VGallery, GalleryStepper, Gallery},
  data() {
    return {
      step: 1,
      valid: true,
      location: '',
      date: '',
      datepicker: false,
      rules: {
        globalRules: [
          v => !!v || v === 0 || 'Обязательное поле',
        ],
      },
      album: {},
      photos: null,
      uploading: false,
      fileInputErrorMessages: [],
      loading: false,
      gallery: [],
      page: 1,
      total: 0,

      failedUploads: [],
      successfulUploads: [],
      locations: [],
      availableDates: [],
    }
  },
  methods: {
    checkDate(date) {
      // Здесь вы должны реализовать логику проверки доступности даты
      // Например, если availableDates содержит доступные даты, вы можете проверить, содержится ли переданная дата в этом массиве
      console.log(date);
      return this.availableDates.includes(date);
    },
    nextStep() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.step++
      }
    },
    handleFileChange(files) {
      this.loading = true;

      if (!files || files.length === 0) {
        this.photos = null;
        this.loading = false;
        return;
      }

      const fileTypeValidate = new Promise(function (resolve, reject) {
        // Валидация формата файлов
        const validFormats = ['image/jpeg']; // Добавь нужные форматы
        const invalidFilesFormat = files.filter(file => !validFormats.includes(file.type));

        if (invalidFilesFormat.length > 0) {
          reject('В выбранных файлах содержатся недопустимые к загрузке файлы. Разрешены только jpeg файлы.');
        } else {
          resolve(files);

        }
      });
      const findDuplicateSizeFiles = files => {
        return new Promise((resolve) => {
          const duplicates = [];
          const processedIndexes = new Set();

          files.forEach((file, index) => {

            for (let i = index + 1; i < files.length; i++) {
              if (files[i].size === file.size) {
                // Проверим что такой id мы не добавляли в дублированные файлы
                if (!processedIndexes.has(index)) {
                  processedIndexes.add(index);
                  duplicates.push(file);
                }

                // Проверим что такой id мы не добавляли в дублированные файлы
                if (!processedIndexes.has(i)) {
                  processedIndexes.add(i);
                  duplicates.push(files[i]);
                }
              }
            }
          });

          if (duplicates.length === 0) {
            // Все файлы разные
            resolve(true);
          } else {
            // Есть файлы с одинаковыми размерами
            resolve(duplicates);
          }
        });
      };
      const calculateHashes = files => {
        const hashPromises = files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
              const arrayBuffer = event.target.result;
              const hashHex = CryptoJS.MD5(CryptoJS.lib.WordArray.create(arrayBuffer)).toString();
              resolve(hashHex);
            };

            reader.onerror = function (error) {
              reject(error);
            };

            reader.readAsArrayBuffer(file);
          });
        });

        return Promise.all(hashPromises);
      };
      const checkUniqueHashes = hashes => {
        const uniqueHashes = new Set(hashes);
        if (uniqueHashes.size === hashes.length) {
          return true;
        } else {
          return Promise.reject('В выбранных файлах содержатся дубликаты');
        }
      };

      // начнем проверку выбранных файлов
      this.photos = null;
      this.fileInputErrorMessages = [];
      fileTypeValidate
        .then((files) => {
          return findDuplicateSizeFiles(files);
        })
        .then((files) => {
          if (files !== true) {
            return calculateHashes(files)
          }

          this.fileInputErrorMessages = [];
          return Promise.resolve(true);
        })
        .then((hashes) => {
          if (hashes !== true) {
            return checkUniqueHashes(hashes);
          }

          this.fileInputErrorMessages = [];
          return Promise.resolve(true);
        })
        .then(state => {
          if (state) {
            this.photos = files;
            this.fileInputErrorMessages = [];
          }
        })
        .catch((err) => {
          if (typeof (err) == "string") {
            this.fileInputErrorMessages = [err];
          } else {
            this.fileInputErrorMessages = ['Произошла ошибка'];
            console.error(err);
          }
        })
        .finally(
          () => {
            this.loading = false;
          }
        )
    },
    uploadPhotos() {
      // Функция выгрузки фотографии в созданный альбом
      const uploadPhotoOnServer = (photo, locationId, dateAt, maxRetries = 3) => {
        let formData = new FormData();
        formData.append('file', photo)
        formData.append('locationId', locationId)
        formData.append('dateAt', dateAt)

        // Функция для повторной попытки с использованием рекурсии
        const retry = (attempt) => {
          if (attempt <= maxRetries) {
            return axios({
              baseURL: this.URL + '/api/photographer/free_photos',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`
              },
              method: 'POST',
              data: formData,
            })
              .then(response => response.data)
              .catch(() => retry(attempt + 1));
          } else {
            this.failedUploads.push(photo);
            console.error('Не удалось выгрузить фотографию: ', photo);
            return Promise.resolve();
          }
        };

        return retry(1);
      };

      // Очистка и установка переменных перед выгрузкой
      this.failedUploads = [];
      this.successfulUploads = [];
      this.uploading = true;
      this.setIsLoading(true);

      // Последовательная выгрузка фотографий
      return this.photos.reduce((accumulator, photo) => {
          // Создание promise для текущей фотографии и добавление в последовательность
          const currentPromise = () => uploadPhotoOnServer(photo, this.location, this.date)
            .then(successfulUploadResponse => {
              if (successfulUploadResponse) {
                this.successfulUploads.push(successfulUploadResponse);
              }
            });

          return accumulator.then(() => currentPromise());
        },
        Promise.resolve())
        .then(() => {
          this.total = this.successfulUploads.length;
          this.gallery = this.successfulUploads;
          this.nextStep();
        })
        .catch()
        .finally(() => {
          this.setIsLoading(false);
        });
    },
    uploadAgain() {
      this.step = 1;
      this.location = '';
      this.date = '';
      this.photos = null;
      this.album = {};
    },
    async next() {
      this.page++;
      await this.getPhotosAfterUpload()
    },
    async removePhoto(id) {
      //Удаление фотографии из загруженных фоток
      this.setIsLoading(true)
      try {
        await axios({
          baseURL: `${this.URL}/api/photographer/free_photos/${id}`,
          headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'DELETE',
        });
        this.changeAlertType('success');
        this.setIsAlert(true)
        this.changeAlertMessage('Фотография удалена')
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
        this.total--
        this.gallery = this.gallery.filter(item => item.id !== id)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    loadLocations() {
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      };

      axios.get(`${this.URL}/api/photographer/free_locations`, config)
        .then(response => {
          // Обновляем данные компонента с полученными локациями
          this.locations = response.data;
        })
        .catch(error => {
          console.error('Ошибка при загрузке локаций:', error);
        });
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'logoutUser'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },
    selectedLocation() {
      return this.locations.find(location => location.id === this.location)
    },
    returnGallery() {
      return this.gallery
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    uploadProgress() {
      // Вычисляем процент успешно загруженных файлов
      return (this.successfulUploads.length / this.photos.length) * 100;
    },
    ...mapGetters(['URL', 'token', 'user'])
  },
  watch: {
    location(locationId) {
      const newLocation = this.locations.find(location => location.id === locationId)

      if (newLocation) {
        this.date = null;
        this.availableDates = newLocation.dates.map(date => {
          const isoDate = new Date(date.dateAt).toISOString(); // Преобразование в формат ISO
          return isoDate.split('T')[0];
        });
      }
    },
    step(value) {
      // Если возвращаемся на шаг выбора фоток, то очищаем, то что выбирали
      if (value === 2) {
        this.photos = null;
      }
      this.uploading = false;
    }
  },
  mounted() {
    // Вызываем метод для загрузки локаций при монтировании компонента
    this.loadLocations();
  },
}
</script>

<style lang="scss" scoped>
.stepper-title {
  margin: 8px 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #323232;
  @media (max-width: 1903px) {
    font-size: 26px;
  }

  @media (max-width: 1263px) {
    font-size: 22px;
  }

  @media (max-width: 959px) {
    font-size: 20px;
  }

  @media (max-width: 599px) {
    font-size: 18px;
  }
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;

}

.descr {
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.06em;
  color: #818181;
}

.descr-2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-transform: uppercase;
  max-width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.06em;
  color: #818181;
}

.final-img {
  width: 390px;
  height: 435px;
  margin: 0 auto 12px;
}

@media (max-width: 1903px) {
  .final-img {
    width: 280px;
    height: 313px;
  }
}

@media (max-width: 1263px) {
  .descr {
    font-size: 16px;
  }
  .descr-2 {
    font-size: 14px;
  }

}

@media (max-width: 959px) {
  .descr {
    font-size: 14px;
  }
  .descr-2 {
    font-size: 12px;
  }
  .final-img {
    width: 200px;
    height: 223px;
  }
}
</style>
