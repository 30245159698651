<template>
  <div id="lightgallery" v-if="gallery.length > 0">
    <gallery :images="images" :index="index" @close="index = null"></gallery>
    <div
      class="image gallery-item"
      v-for="(img, i) in mappedGallery"
      :key="img.id"
      @click="index = i"
      :style="{ background: 'url(' + URL + img.small + ') center / cover no-repeat' }"
    >
      <div class="btns">
        <v-btn
          class="btn"
          fab
          x-small
          @click.stop.prevent="del(img.id)"
          v-if="hasDelete"
        >
          <v-icon>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
        <v-btn
          class="btn"
          fab
          x-small
          @click.stop.prevent="cart(img)"
          v-if="search || bookmarks || cartUser"
        >
          <v-icon color="primary" v-if="img.cart">
            mdi-cart-check
          </v-icon>
          <v-icon v-else>
            mdi-cart-arrow-down
          </v-icon>
        </v-btn>
        <v-btn
          class="btn"
          fab
          x-small
          @click.stop.prevent="fav(img)"
          v-if="search || bookmarks"
        >
          <v-icon color="error" v-if="img.bookmarks">
            mdi-cards-heart
          </v-icon>
          <v-icon v-else>
            mdi-cards-heart-outline
          </v-icon>
        </v-btn>
        <v-btn
          class="btn"
          fab
          x-small
          @click.stop.prevent="edit(img.id)"
          v-if="editor"
        >
          <v-icon color="primary">
            mdi-circle-edit-outline
          </v-icon>
        </v-btn>
        <span class="status" v-if="status">
        <v-icon color="primary" v-if="img.status === 'waiting'">mdi-reload</v-icon>
        <v-icon color="primary" v-if="img.status === 'approved'">mdi-check-circle</v-icon>
        <v-icon color="primary" v-if="img.status === 'rejected'">mdi-close-circle-outline</v-icon>
      </span>
        <span class="price" v-if="price">
          <v-chip small>{{ img.price }} ₽</v-chip>
      </span>
      </div>
    </div>
  </div>
  <div class="empty" v-else>- Список пуст -</div>
</template>

<script>
import VueGallery from 'vue-gallery';
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    'gallery': VueGallery
  },
  name: "VGallery",
  props: {
    gallery: {
      type: Array,
      required: true,
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    description: {
      type: Boolean,
      default: false
    },
    price: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    bookmarks: {
      type: Boolean,
      default: false
    },
    editor: {
      type: Boolean,
      default: false
    },
    cartUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      index: null,
    }
  },
  mounted() {

  },
  watch: {},
  computed: {
    images() {
      if (this.search) {
        return this.gallery.map(i => `${this.URL}${i.small}`)
      } else if (this.bookmarks || this.cartUser) {
        return this.mappedGallery.map(i => `${this.URL}${i.small}`)
      } else {
        return this.gallery.map(i => `${this.URL}${i.full}`)
      }
    },
    mappedGallery() {
      return this.gallery
    },

    ...mapGetters(['URL'])
  },
  methods: {
    del(id) {
      this.$emit('delete', id)
    },
    fav(id) {
      this.$emit('fav', id)
    },
    cart(id) {
      this.$emit('cart', id)
    },
    edit(id) {
      this.$emit('edit', id)
    },
    ...mapActions(['setIsLoading'])
  }
}
</script>

<style scoped>
/*Стили галерии общие вынесены в main.scss*/
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 4px;
}

.status {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.price {
  text-decoration: none;
}
</style>