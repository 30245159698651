import Vue from 'vue'
import Vuex from 'vuex'
import loader from "./loader";
import URLS from "./URLS";
import user from "./user/user";
import alert from "./alert";
import photoLocations from "./user/photoLocations";
import albumsLocations from "./user/albumsLocations";
import saleLocations from "./user/saleLocations";
import notifications from "./user/notifications";
import paymentRequests from "@/store/user/paymentRequests";

Vue.use(Vuex)

const modules = {
  loader,
  URLS,
  user,
  alert,
  photoLocations,
  albumsLocations,
  saleLocations,
  notifications,
  paymentRequests
}
const store = new Vuex.Store({
  modules,
});

export default store;
