<template>
  <h1>{{ title }}</h1>
</template>

<script>
export default {
  name: "TitlteH1",
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #323232;
  font-size: 46.194px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.541px;
}

@media (max-width: 1903px) {
  h1 {
    font-size: 54px;
  }
}

@media (max-width: 1263px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 959px) {
  h1 {
    font-size: 28px;
  }
}

@media (max-width: 599px) {
  h1 {
    font-size: 20px;
  }
}
</style>
