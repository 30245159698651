import store from "@/store/index";
import axios from "axios";
import router from "@/router";
// ЭТОТ стор содержит уведомления
export default {
  state: {
    notifications: [],
  },

  getters: {
    notifications: state => state.notifications
  },

  mutations: {
    GET_NOTIFICATIONS(state, array) {
      state.notifications = array
    },
  },

  actions: {
    async closeNotification({commit}, payload) {
      try {
        const response = await axios({
          baseURL: store.getters.URL + `/api/photographer/notifications/${payload.id}`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': 'Bearer ' + store.getters.token
          },
          method: 'PATCH',
          data: {
            display: false
          }
        })
        await store.dispatch('fetchNotifications')
        this.setIsAlert(true);
        this.changeAlertMessage('Уведомление прочитано')
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {

      }
    },

    getNotifications({commit}, payload) {
      commit('GET_NOTIFICATIONS', payload.array)
    },
    async fetchNotifications() {
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/photographer/notifications',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${store.getters.token}`
          },
          method: 'GET',
        })

        await store.dispatch('getNotifications', {array: response.data})
      } catch (e) {
        console.log(e)
        if (e.response.data.message === 'Expired JWT Token' || e.response.data.message === 'Invalid credentials.') {
          await store.dispatch('logoutUser')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      } finally {
      }
    }
  },
};
