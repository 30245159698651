<template>
  <div class="view-page">
    <TitlteH1 title="Настройки профиля" class="mb-3"/>
    <v-row class="mt-10">
      <v-col cols="12" lg="5">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
        >
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="user.email"
                :rules="rules.emailRules"
                key="email"
                label="Почта"
                required
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="user.firstName"
                :rules="rules.globalRules"
                key="name"
                label="Имя"
                required
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="user.lastName"
                :rules="rules.globalRules"
                key="name"
                label="Фамилия"
                required
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="userPhone"
                key="phone"
                label="Телефон"
                :rules="rules.globalRules"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Новый пароль"
            :rules="password ? [rules.password.registerMin] : []"
            @click:append="showPassword = !showPassword"
          />
          <v-row class="mt-3 justify-center">
            <v-col cols="12">
              <v-btn
                :small="mobile"
                type="submit"
                color="primary"
                block
              >
                Сохранить
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-col>
      <v-col cols="12" lg="1"></v-col>
      <v-col cols="12" lg="6">

        <Locations/>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import {mapActions, mapGetters} from "vuex";
import Locations from "@/views/settings/Locations";
import axios from "axios";
import Subtitle from "@/components/typography/Subtitle";

export default {
  name: "Settings",
  components: {Subtitle, Locations, TitlteH1},
  data() {
    return {
      password: '',
      showPassword: false,
      valid: true,
      rules: {
        emailRules: [
          v => !!v || 'E-mail обязателен',
          v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
        ],
        globalRules: [
          v => !!v || 'Поле обязательно',
        ],
        password: {
          registerMin: v => v.length >= 5 || 'Пароль должен быть длиннее 4 символов'
        },
      }
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        await axios({
          baseURL: this.URL + '/api/photographer/user',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': 'Bearer ' + this.token
          },
          method: 'PATCH',
          data: JSON.stringify({
            "plainPassword": this.password ? this.password : '',
            "lastName": this.user.lastName,
            "firstName": this.user.firstName,
            "phone": this.user.phone.replace(/[^\d]/g, '')
          })
        });
        this.setIsAlert(true);
        this.changeAlertMessage('Настройки изменены')
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        await this.$store.dispatch('loginUser', {token: this.token})
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    }
    ,
    ...mapActions(['fetchLocations', 'setIsLoading', 'setIsAlert', 'changeAlertType', 'changeAlertMessage', 'loginUser'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    userPhone() {
      return this.user.phone.replace(/(.)(...)(...)(..)(..)/, '+$1 ($2) $3-$4-$5')
    },
    ...mapGetters(['user', 'URL', 'token'])
  },
  mounted() {
    this.loginUser({token: this.token})
    this.fetchLocations()
  }
}
</script>

<style scoped>

</style>
