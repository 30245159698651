<template>
  <div class="locations">
    <div class="locations-title">Ваши локации</div>
    <v-expansion-panels
      flat
      tile
    >
      <v-expansion-panel
        v-for="location in photoLocations"
        :key="location.id"
        active-class="location-active"
      >
        <v-expansion-panel-header class="pa-2" color="#F1F2F5">
          · {{ location.title }}, {{ location.address }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4" color="#F1F2F5">
          <div class="map" v-html="location.mapIframe">

          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Locations",
  computed: {
    ...mapGetters(['photoLocations'])
  }
}
</script>

<style lang="scss" scoped>
.locations {
  max-height: 760px;
  padding-right: 40px;
  overflow-y: auto;
  @media (max-width: 1903px) {
    max-height: 500px;
  }

  @media (max-width: 599px) {
    padding-right: 20px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 100px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }


}

.map {
  position: relative;
  height: 466px;

  &::v-deep iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @media (max-width: 1903px) {
    height: 420px;
  }

  @media (max-width: 1263px) {
    height: 380px;
  }

  @media (max-width: 959px) {
    height: 360px;
  }

  @media (max-width: 599px) {
    height: 320px;
  }
}

.locations-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 133%;
  letter-spacing: 0.15px;
  color: #222222;
  margin-bottom: 8px;
}

.location-active {

  color: #4442D2 !important;

  .mdi-chevron-down::before {
    color: #4442D2 !important;
  }
}

</style>
