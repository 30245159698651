export default {
  state: {
    isLoading: false,
  },

  getters: {
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    TOGGLE_LOADING(state, boolean) {
      state.isLoading = boolean
    },
  },

  actions: {
    setIsLoading({commit}, bool) {
      commit('TOGGLE_LOADING', bool)
    },
  },
};
