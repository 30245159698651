<template>
  <v-card>
    <v-card-actions class="pb-0">
      <v-spacer/>
      <v-btn
        icon
        @click="$emit('close')"
        x-small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-title class="text-center justify-center pt-0">Войдите для продолжения <br> работы с сервисом</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="login"
        v-if="tab"
      >
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="user.email"
              :rules="rules.emailRules"
              key="email"
              label="Почта"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.password.required, rules.password.registerMin]"
              :type="showPassword ? 'text' : 'password'"
              label="Пароль"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-checkbox
              v-model="policy"
              :rules="rules.policyRules"
              label="Соглашаюсь с Политикой конфиденциальности"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!--        <v-row class="justify-center">-->
        <!--          <v-col cols="10" class="py-0">-->
        <!--            <v-text-field-->
        <!--              v-model.trim="user.phone"-->
        <!--              key="phone"-->
        <!--              label="Телефон"-->
        <!--              v-maska="'+7(###)###-##-##'"-->
        <!--              :rules="rules.phoneRules"-->
        <!--              required-->
        <!--            />-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row class="justify-center">
          <v-col cols="10">
            <v-btn
              :small="mobile"
              type="submit"
              color="primary"
              block
              :loading="isLoading"
            >
              Войти
            </v-btn>
          </v-col>
        </v-row>

      </v-form>
      <v-form
        ref="formReg"
        v-model="validReg"
        lazy-validation
        @submit.prevent="registration"
        v-else
      >
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="reg.firstName"
              :rules="rules.globalRules"
              key="name"
              label="Имя"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="reg.lastName"
              :rules="rules.globalRules"
              key="name"
              label="Фамилия"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="reg.phone"
              key="phone"
              label="Телефон"
              v-maska="'+7(###)###-##-##'"
              :rules="rules.phoneRules"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="reg.email"
              :rules="rules.emailRules"
              key="email"
              label="Почта"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model="reg.plainPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.password.required, rules.password.registerMin]"
              :type="showPassword ? 'text' : 'password'"
              label="Пароль"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="10">
            <v-btn
              :small="mobile"
              type="submit"
              color="primary"
              block
              :loading="isLoading"
            >
              Зарегистрироваться
            </v-btn>
          </v-col>
        </v-row>

      </v-form>
      <v-row>
        <v-col cols="12" class="text-center py-0 pt-2">
          <a href="#" @click.prevent="tab = !tab">
            {{ tab ? 'Регистрация' : 'Войти' }}
          </a>
        </v-col>
        <v-col cols="12" class="text-center py-0">
          <a href="#" @click.prevent="$emit('resetBtn')">
            Забыли пароль?
          </a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "AuthDialog",
  data() {
    return {
      tab: true,
      valid: true,
      validReg: true,
      showPassword: false,
      policy: true,
      user: {
        phone: '',
        email: '',
        password: '',
      },
      reg: {
        plainPassword: '',
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      rules: {
        password: {
          required: value => !!value || 'Пароль обязателен',
          registerMin: v => v.length >= 2 || 'Пароль должен быть длиннее 3 символов'
        },
        phoneRules: [
          v => !!v || 'Телефон обязателен',
        ],
        globalRules: [
          v => !!v || 'Поле обязательно',
        ],
        policyRules: [
          v => !!v || 'Поле обязательно',
        ],
        emailRules: [
          v => !!v || 'E-mail обязателен',
          v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
        ],
      }
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['URL', 'isLoading'])
  },
  methods: {
    async login() {
      //валидируем форму
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      //запрос на апи за ключом
      try {
        const response = await axios({
          baseURL: this.URL + '/api/photographer/authentication_token',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          data: JSON.stringify({
            email: this.user.email,
            password: this.user.password,
          })
        })
        // записываем ключ
        await this.loginUser({token: response.data.token});
        // закрываем попап
        this.$emit('close');
        await this.$router.push({name: 'profile'})
      } catch (e) {
        if (e.response.data.message === "Недействительные аутентификационные данные.") {
          this.setIsAlert(true)
          this.changeAlertMessage('Неверные логин/пароль!')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 5000)
        } else {
          this.setIsAlert(true)
          this.changeAlertMessage('Произошла ошибка')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 5000)
        }
      } finally {
        this.setIsLoading(false)
      }
    },
    async registration() {
      //валидируем форму
      this.$refs.formReg.validate();
      if (!this.$refs.formReg.validate()) {
        return
      }
      this.setIsLoading(true)
      //запрос на апи за ключом
      try {
        const response = await axios({
          baseURL: this.URL + '/api/photographer/register',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          data: JSON.stringify({...this.reg, phone: this.reg.phone.replace(/[^\d]/g, '')})
        })
        this.tab = true;
        this.setIsAlert(true)
        this.changeAlertType('success')
        this.changeAlertMessage('Регистрация прошла успешно. Войдите.')
        setTimeout(() => {
          this.setIsAlert(false)
        }, 1500);
        this.user = {
          phone: this.reg.phone,
          email: this.reg.email,
          password: this.reg.plainPassword,
        }

        this.reg = {
          plainPassword: '',
          phone: '',
          firstName: '',
          lastName: '',
          email: '',
        };
      } catch (e) {
        if (e.response.status === 422) {
          this.setIsAlert(true)
          this.changeAlertType('error')
          this.changeAlertMessage('Данный пользователь уже зарегистрирован!')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 1500)
        } else {
          this.setIsAlert(true)
          this.changeAlertMessage('Произошла ошибка')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 5000)
        }
      } finally {
        this.setIsLoading(false)
      }
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'loginUser'])
  }
}
</script>

<style scoped>

</style>
