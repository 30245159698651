<template>
  <div ref="lg" id="lightgallery" v-if="gallery.length > 0">
    <a
      v-for="img in gallery"
      :key="img.id"
      :href="URL+img.full"
      class="gallery-item"
    >
      <img
        :src="URL+img.small"
      >
      <v-btn
        class="delete-btn"
        fab
        x-small
        @click.stop.prevent="del(img.id)"
        v-if="hasDelete"
      >
        <v-icon x-small>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
      <span class="status" v-if="status">
        <v-icon color="primary" v-if="img.status === 'waiting'">mdi-reload</v-icon>
        <v-icon color="primary" v-if="img.status === 'approved'">mdi-check-circle</v-icon>
        <v-icon color="primary" v-if="img.status === 'rejected'">mdi-close-circle-outline</v-icon>
      </span>
      <span class="price" v-if="price">
          <v-chip small>{{ img.price }} ₽</v-chip>
      </span>
    </a>
  </div>
  <div class="empty" v-else>- Список пуст -</div>
</template>

<script>
import '@/assets/js/libs/lightgallery.min.js'
import '@/scss/libs/lightgallery.scss'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GalleryStepper",
  props: {
    gallery: {
      type: Array,
      required: true,
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    description: {
      type: Boolean,
      default: false
    },
    price: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {
    window.lightGallery(this.$refs.lg);

  },
  watch: {
    gallery() {
      setTimeout(() => {
        window.lightGallery(this.$refs.lg);
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['URL'])
  },
  methods: {
    del(id) {
      this.$emit('delete', id)
    },
    ...mapActions(['setIsLoading'])
  }
}
</script>

<style scoped>
/*Стили галерии общие вынесены в main.scss*/
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 33;
}

.status {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
}

.price {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
}
</style>