<template>
  <div class="view-page">
    <TitlteH1 title="Уведомления" class="mb-12"/>
    <ul class="list">
      <li
        v-for="item in notifications"
        :key="item.id"
        :class="['item', { active: item.display }]"
      >
        <div class="item-container">
          <!--          <p class="item-date">{{ item.date }}</p>-->
          <p class="item-title">Новое уведомление</p>
          <p class="item-desc">
            {{ item.message }}
          </p>
          <v-btn @click="$router.push(item.button)" color="primary" small>Открыть альбом</v-btn>
          <v-btn class="mx-4" @click="closeNotification(item)" color="secondary" small>Прочитано</v-btn>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import {mapActions, mapGetters} from "vuex";
import notifications from "@/store/user/notifications";

export default {
  name: "Notifications",
  components: {TitlteH1},
  data() {
    return {}
  },
  methods: {
    ...mapActions(['closeNotification'])
  },
  computed: {
    notifications() {
      return notifications
    },
    ...mapGetters(['notifications'])
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.check {
  font-size: 18px;
  line-height: 16px;
  color: #4c4c4c;
  border-bottom: 1px solid;
}

.list {
  padding-top: 34px;
  padding-left: 0;
  @media (max-width: 959px) {
    padding-left: 42px;
  }
  @media (max-width: 540px) {
    padding-left: 20px;
  }
}

.item {
  list-style: none;

  &:not(:last-child) {
    padding-bottom: 49px;

    @media (max-width: 540px) {
      padding-bottom: 29px;
    }
  }

  &.active {
    .item-container {
      background-color: #f9f9f9;
    }
  }
}

.item-container {
  border-bottom: 1px solid #9c9c9c;
  position: relative;
  padding: 12px;
}

.item-date {
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: #9c9c9c;
}

.item-title {
  padding-bottom: 7px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #222;
}

.item-desc {
  max-width: 550px;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
  padding-bottom: 12px;
}

</style>
