<template>
  <v-app>
    <v-navigation-drawer
      app
      v-if="isAuth && $route.name !== 'home'"
      v-model="showDrawer"
      :width="mobile ? '280' : '370'"
    >
      <Drawer/>
    </v-navigation-drawer>
    <BurgerButton
      v-if="isAuth && $route.name !== 'home' && $vuetify.breakpoint.mdAndDown"
      @click="showDrawer = true"
    />
    <v-main>
      <router-view/>
    </v-main>
    <transition name="fade">
      <Loader v-if="isLoading"/>
    </transition>
    <transition name="fade">
      <v-alert
        v-if="isAlert"
        class="global-alert"
        :type="alertType"
        v-html="alertMessage"
      />
    </transition>
  </v-app>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Loader";
import Drawer from "@/components/drawer/Drawer";
import BurgerButton from "@/components/BurgerButton";

export default {
  name: 'App',
  components: {BurgerButton, Drawer, Loader},
  data() {
    return {
      showDrawer: null,
    }
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchNotifications'])
  },
  mounted() {
    if (this.$route.name !== 'home') {
      this.fetchNotifications()
      setInterval(() => {
        this.fetchNotifications()
      }, 120000)
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['isLoading', 'isAlert', 'alertType', 'alertMessage', 'isAuth'])
  }
};
</script>
