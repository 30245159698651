import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "@/views/MainPage";
import NotFound from "@/views/NotFound";
import Profile from "@/views/profile/Profile";
import Free from "@/views/free/Free.vue";
import store from "@/store";
import Settings from "@/views/settings/Settings";
import Notifications from "@/views/notifications/Notifications";
import Photos from "@/views/photos/Photos";
import Deals from "@/views/deals/Deals";
import Sale from "@/views/sale/Sale";
import Balance from "@/views/balance/Balance.vue";
import History from "@/views/balance/History.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true,
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      auth: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      auth: true,
    }
  },
  {
    path: '/photos',
    name: 'photos',
    component: Photos,
    meta: {
      auth: true,
    }
  },
  {
    path: '/free',
    name: 'free',
    component: Free,
    meta: {
      auth: true,
    }
  },
  {
    path: '/deals',
    name: 'deals',
    component: Deals,
    meta: {
      auth: true,
    }
  },
  {
    path: '/sale',
    name: 'sale',
    component: Sale,
    meta: {
      auth: true,
    }
  },
  {
    path: '/balance',
    name: 'balance',
    component: Balance,
    meta: {
      auth: true,
    }
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      auth: true,
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})
router.beforeEach((to, from, next) => {
  if (store.getters.user === undefined) {
    store.dispatch('logoutUser').then(r => r)
  }

  if (store.getters.token) {
    //если пользователь залогинен, то перед каждым открытием страницы запрашиваем его данные.
    //тут и токен проверяется и данные обновляются
    store.dispatch('loginUser', {token: store.getters.token})
      .then(r => {
      })
      .catch(e => store.dispatch('logoutUser'))
  }
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.isAuth) {
      router.push({name: 'notFound'})
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
