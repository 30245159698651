<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1"/>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="step > 2"/>
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="step > 3"/>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="toStepTwo">
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-select :items="photoLocations" item-text="title" item-value="id" :rules="rules.globalRules"
                        label="Выберите локацию" v-model="location"></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-dialog ref="dialog" v-model="datepicker" :return-value.sync="dateAt" persistent width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormatted" label="Дата продажи" readonly v-bind="attrs" v-on="on"
                                :rules="rules.globalRules"></v-text-field>
                </template>
                <v-date-picker v-model="dateAt" scrollable first-day-of-week="1" :max="currentDay">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datepicker = false">
                    Назад
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dateAt)">
                    Ок
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-text-field key="phone" label="Номер покупателя" v-maska="'+7(###)###-##-##'" v-model.trim="client"
                            :rules="rules.phoneRules"
                            hint="Предупредите клиента, что фотографии станут ему доступны к просмотру и скачиванию только ПОСЛЕ МОДЕРАЦИИ."
                            autocomplete="off"/>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-text-field key="price" v-maska="'#####'" label="Укажите стоимость продажи" v-model.number="price"
                            :rules="rules.globalRules" autocomplete="off"/>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-textarea label="Комментарии" no-resize rows="1" :rules="rules.commentsRules"
                          hint="Не более 200 символов" v-model.trim="comments"/>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-select :items="photographers" :item-text="item => item.lastName+' '+item.firstName" item-value="id"
                        label="Выберите фотографа" v-model="selectedPhotographer" :disabled="!location"></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-select :items="assistants" :item-text="item => item.lastName+' '+item.firstName" item-value="id"
                        label="Выберите помощников" v-model="selectedAssistants"
                        :disabled="!location || assistants.length === 0 || !assistants" multiple></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" lg="6">
              <v-btn :small="mobile" type="submit" color="primary" block>
                Далее
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <div class="stepper-title">
              Выберите фото для загрузки
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <v-file-input ref="fileInput" accept="image/jpeg" label="Выбрать..." multiple counter show-size
                          prepend-icon="mdi-image" v-model="photos" @change="handleFileChange"
                          :error-messages="fileInputErrorMessages"/>
            <v-progress-linear v-if="loading" indeterminate color="cyan" class="mt-2 mb-2"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block outlined @click="step = 1">
              Назад
            </v-btn>
          </v-col>
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block :disabled="!photos" @click="uploadPhotos">
              Далее
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3">
        <div class="final-img">
          <v-img src="@/assets/img/step4.png"/>
        </div>
        <v-row class="justify-center">
          <v-col cols="12" lg="6">
            <div class="stepper-title">
              Ваши фото успешно загружены
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="descr text-center">Процесс модерации вы можете отследить на странице «Фото онлайн»</div>
          </v-col>
        </v-row>
        <v-row class="justify-center mt-4">
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block outlined @click="uploadAgain">
              Новая
            </v-btn>
          </v-col>
          <v-col cols="6" lg="6">
            <v-btn :small="mobile" type="submit" color="primary" block @click="$router.push({name: 'deals'})">
              Продажи на месте
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import Gallery from "@/components/gallery/GalleryStepper";
import GalleryStepper from "@/components/gallery/GalleryStepper";
import {format} from "date-fns";
import CryptoJS from "crypto-js";

export default {
  name: "StepperSale",
  components: {GalleryStepper, Gallery},
  data() {
    return {
      step: 1,
      valid: true,
      location: '',
      dateAt: format(new Date(), 'yyy-MM-dd'),
      datepicker: false,
      rules: {
        globalRules: [
          v => !!v || v === 0 || 'Обязательное поле',
        ],
        phoneRules: [
          v => {
            if (!v) {
              return 'Обязательное поле';
            }

            if (!v.startsWith('+79') && !v.startsWith('79') && !v.startsWith('+7(9') && !v.startsWith('7(9')) {
              return 'Необходимо проверить код сотового оператора';
            }

            if (v.length !== 16) {
              return 'Необходимо проверить введенное количество цифр сотового телефона'
            }

            return true;
          }
        ],
        commentsRules: [
          (v => v.length < 200 || 'Не более 200 символов')
        ]
      },
      album: {},
      photos: null,
      loading: false,
      fileInputErrorMessages: [],
      errorPhotos: [],
      gallery: [],
      page: 1,
      total: 0,
      client: '',
      comments: '',
      price: '',

      photographers: [],
      selectedPhotographer: null,
      assistants: [],
      selectedAssistants: null
    }
  },
  methods: {
    handleFileChange(files) {
      this.loading = true;

      if (!files || files.length === 0) {
        this.photos = null;
        this.loading = false;
        return;
      }

      const fileTypeValidate = new Promise(function (resolve, reject) {
        // Валидация формата файлов
        const validFormats = ['image/jpeg']; // Добавь нужные форматы
        const invalidFilesFormat = files.filter(file => !validFormats.includes(file.type));

        if (invalidFilesFormat.length > 0) {
          reject('В выбранных файлах содержатся недопустимые к загрузке файлы. Разрешены только jpeg файлы.');
        } else {
          resolve(files);

        }
      });
      const findDuplicateSizeFiles = files => {
        return new Promise((resolve) => {
          const duplicates = [];
          const processedIndexes = new Set();

          files.forEach((file, index) => {

            for (let i = index + 1; i < files.length; i++) {
              if (files[i].size === file.size) {
                // Проверим что такой id мы не добавляли в дублированные файлы
                if (!processedIndexes.has(index)) {
                  processedIndexes.add(index);
                  duplicates.push(file);
                }

                // Проверим что такой id мы не добавляли в дублированные файлы
                if (!processedIndexes.has(i)) {
                  processedIndexes.add(i);
                  duplicates.push(files[i]);
                }
              }
            }
          });

          if (duplicates.length === 0) {
            // Все файлы разные
            resolve(true);
          } else {
            // Есть файлы с одинаковыми размерами
            resolve(duplicates);
          }
        });
      };
      const calculateHashes = files => {
        const hashPromises = files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
              const arrayBuffer = event.target.result;
              const hashHex = CryptoJS.MD5(CryptoJS.lib.WordArray.create(arrayBuffer)).toString();
              resolve(hashHex);
            };

            reader.onerror = function (error) {
              reject(error);
            };

            reader.readAsArrayBuffer(file);
          });
        });

        return Promise.all(hashPromises);
      };
      const checkUniqueHashes = hashes => {
        const uniqueHashes = new Set(hashes);
        if (uniqueHashes.size === hashes.length) {
          return true;
        } else {
          return Promise.reject('В выбранных файлах содержатся дубликаты');
        }
      };

      // начнем проверку выбранных файлов
      this.photos = null;
      this.fileInputErrorMessages = [];
      fileTypeValidate
        .then((files) => {
          return findDuplicateSizeFiles(files);
        })
        .then((files) => {
          if (files !== true) {
            return calculateHashes(files)
          }

          this.fileInputErrorMessages = [];
          return Promise.resolve(true);
        })
        .then((hashes) => {
          if (hashes !== true) {
            return checkUniqueHashes(hashes);
          }

          this.fileInputErrorMessages = [];
          return Promise.resolve(true);
        })
        .then(state => {
          if (state) {
            this.photos = files;
            this.fileInputErrorMessages = [];
          }
        })
        .catch((err) => {
          if (typeof (err) == "string") {
            this.fileInputErrorMessages = [err];
          } else {
            this.fileInputErrorMessages = ['Произошла ошибка'];
            console.error(err);
          }
        })
        .finally(
          () => {
            this.loading = false;
          }
        )
    },

    toStepTwo() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.step = 2;
    },
    async createRemoteAlbum() {
      //Получаем дату и место в первом степе, записываем в album и переходим на 2 шаг
      //Место передаю только ID этого места. После его надо будет достать из общего массива
      //Создаем альбом на беке и записываем ID полученного альбома
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/photographer/sales_direct',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'POST',
          data: JSON.stringify({
            location: `/api/photographer/locations/${this.location}`,
            date: this.dateAt,
            photographer: this.selectedPhotographer,
            assistants: this.selectedAssistants,
            comment: this.comments,
            userPhone: this.client.replace(/[^\d]/g, '').trim(),
            price: this.price,
          })
        })
        this.album = {...this.album, location: this.location, date: this.dateAt, id: response.data.id}
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async uploadPhotos() {
      await this.createRemoteAlbum();
      //Загружаем файлы поштучно
      //В цикле создаем отправку каждого файла
      //Создаем форм дату, кладем туда файл и указывем ID созданного альбома
      //Алертом показываю статус загрузки, закрывая доступность лоадером.
      this.setIsLoading(true);
      this.changeAlertType('info');
      this.setIsAlert(true)
      this.changeAlertMessage('Начинаю загрузку фото...')
      let i = 0;
      let error = 0;
      for (const photo of this.photos) {
        i++
        this.changeAlertMessage(`Загружаю ${i} из ${this.photos.length}`)
        try {
          let formData = new FormData();
          formData.append('file', photo)
          formData.append('salesDirectId', this.album.id)
          await axios({
            baseURL: this.URL + '/api/photographer/sales_direct_photos',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`
            },
            method: 'POST',
            data: formData,
          });
        } catch (e) {
          this.errorPhotos.push(photo)
          error++
          console.log(e)
        }
      }

      this.changeAlertType('success');
      if (this.errorPhotos.length > 0) {
        this.changeAlertMessage(
          `<span style="text-align: center; display: block" >
            Успешная загрузка ${i - error} файла(-ов) <br>
            Неудачная загрузка ${error} файла(-ов) <br>
            Начинаю попытку дозагрузки файла(-ов)
           </span>`
        );
      } else {
        this.changeAlertMessage(
          `<span style="text-align: center; display: block" >
            Успешная загрузка ${i - error} файла(-ов)
           </span>`
        );
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      }
      if (this.errorPhotos.length) {
        this.changeAlertMessage('Начинаю дозагрузку фото...')
        await this.reUploadPhotos()
      } else {
        this.setIsLoading(false);
        this.step = 3;
      }

      // await this.getPhotosAfterUpload();

    },
    async reUploadPhotos() {

      let i = 0;
      let error = 0;
      for (let j = 0; j < 4; j++) {
        console.log('reupload')
        for (const photo of this.errorPhotos) {

          this.changeAlertMessage(`Загружаю ${i} из ${this.errorPhotos.length}`)
          try {
            let formData = new FormData();
            formData.append('file', photo)
            formData.append('salesDirectId', this.album.id)
            await axios({
              baseURL: this.URL + '/api/photographer/sales_direct_photos',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`
              },
              method: 'POST',
              data: formData,
            });
            this.errorPhotos.splice(i, 1)
            i++
          } catch (e) {
            // this.errorPhotos.push(photo)
            error++
            console.log(e)
          }
        }
      }
      if (this.errorPhotos.length) {
        this.changeAlertType('error');
        this.changeAlertMessage(`<span>К сожалению загрузка ${this.errorPhotos.length} файла(-ов) неудалась <br> Попыток загрузки - 3</span>`)
      } else {
        this.changeAlertType('error');
        this.changeAlertMessage(`<span>После дозагрузки все файлы были загружены</span>`)
      }
      this.step = 3;
      this.setIsLoading(false)
      setTimeout(() => {
        this.setIsAlert(false)
      }, 5000)
    }
    ,
    async getPhotosAfterUpload() {
      //загрузка фото на переходе на шаг 4 + для пагинации
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
            Загружаю фото с сервера...
           </span>`
      );
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/photographer/sales_direct/photos?page=${this.page}&album.id=${this.album.id}&itemsPerPage=24&order%5BcreatedAt%5D=desc`,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.total = response.data['hydra:totalItems'];
        this.gallery = [...this.gallery, ...response.data['hydra:member']];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    toModerate() {
      this.step = 4
    },
    uploadAgain() {
      this.step = 1;
      this.location = '';
      this.dateAt = '';
      this.photos = null;
      this.album = {};
      this.comments = '';
      this.client = '';
      this.price = '';
      this.errorPhotos = [];
      this.photographers = [];
      this.assistants = [];
      this.selectedPhotographer = null;
      this.selectedAssistants = null
    },
    async next() {
      this.page++;
      await this.getPhotosAfterUpload()
    },
    filterAssistants(photographers, userId) {
      return photographers.filter(photographer => photographer.id !== userId);
    },
    getDefaultPhotographer(photographerId) {
      if (this.photographers.length === 1) {
        return this.photographers[0];
      } else {
        // Если фотографов больше одного, попытаемся выбрать по user.id
        const photographer = this.photographers.find(photographer => photographer.id === photographerId);
        return photographer || null;
      }
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'logoutUser'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    dateFormatted: {
      get() {
        if (this.dateAt) {
          const [year, month, day] = this.dateAt.split('-');
          return `${day}.${month}.${year}`;
        } else return '';
      },
      set(value) {
        // Ваш логика для обработки установки значения dateFormatted
      },
    },
    selectedLocation() {
      return this.photoLocations.find(location => location.id === this.location)
    },
    returnGallery() {
      return this.gallery
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    currentDay() {
      return format(new Date(), 'yyy-MM-dd')
    },
    ...mapGetters(['photoLocations', 'URL', 'token', 'user'])
  },
  mounted() {
    this.dateAt = this.currentDay
  },
  watch: {
    location(locationId) {
      const newLocation = this.photoLocations.find(location => location.id === locationId)

      if (newLocation) {
        // Обнулим данные
        this.photographers = [];
        this.assistants = [];
        // Используем $nextTick для асинхронного обновления значения
        this.$nextTick(() => {
          this.selectedPhotographer = null;

          // Обновление списка фотографов и помощников при изменении локации
          this.photographers = newLocation.photographers ? newLocation.photographers : [];

          // Используем $nextTick для асинхронного обновления значения
          this.$nextTick(() => {
            this.selectedPhotographer = this.getDefaultPhotographer(this.user.id).id;
          });
        });
      }
    },
    selectedPhotographer(photographerId) {
      const currentLocation = this.photoLocations.find(location => location.id === this.location);
      if (currentLocation) {
        this.selectedAssistants = null;
        this.$nextTick(() => {
          this.assistants = this.filterAssistants(currentLocation.photographers, photographerId);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper-title {
  margin: 8px 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #323232;
  @media (max-width: 1903px) {
    font-size: 26px;
  }

  @media (max-width: 1263px) {
    font-size: 22px;
  }

  @media (max-width: 959px) {
    font-size: 20px;
  }

  @media (max-width: 599px) {
    font-size: 18px;
  }
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;

}

.descr {
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.06em;
  color: #818181;
}

.descr-2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  text-transform: uppercase;
  max-width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.06em;
  color: #818181;
}

.final-img {
  width: 390px;
  height: 435px;
  margin: 0 auto 12px;
}

@media (max-width: 1903px) {
  .final-img {
    width: 280px;
    height: 313px;
  }
}

@media (max-width: 1263px) {
  .descr {
    font-size: 16px;
  }
  .descr-2 {
    font-size: 14px;
  }

}

@media (max-width: 959px) {
  .descr {
    font-size: 14px;
  }
  .descr-2 {
    font-size: 12px;
  }
  .final-img {
    width: 200px;
    height: 223px;
  }
}


</style>
