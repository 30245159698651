<template>
  <div class="subtitle" v-html="subtitle"></div>
</template>

<script>
export default {
  props: ['subtitle'],
  name: "Subtitle"
}
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #818181;

  @media (max-width: 1263px) {
    font-size: 16px;
  }
  @media (max-width: 959px) {
    font-size: 14px;
  }
}
</style>