import store from "@/store/index";
import axios from "axios";
import router from "@/router";
// ЭТОТ стор содержит локации, в которых у фотографа уже есть загруженные альбомы
export default {
  state: {
    albumsLocations: localStorage.getItem('albumsLocations') ? JSON.parse(localStorage.getItem('albumsLocations')) : '',
  },

  getters: {
    albumsLocations: state => state.albumsLocations
  },

  mutations: {
    GET_ALBUMS_LOCATIONS(state, array) {
      state.albumsLocations = array
    },
  },

  actions: {
    getAlbumsLocations({commit}, payload) {
      commit('GET_ALBUMS_LOCATIONS', payload.array)
    },
    async fetchAlbumsLocations() {
      //получаем список всех альбомов загруженных,
      //мапим и вытаскиваем нужные нам параметры(id альбома, дата, id локации)
      //шлем еще один запрос в цикле чтобы вытащить локации по полученным id
      await store.dispatch('setIsLoading', true)
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/photographer/albums_filters',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${store.getters.token}`
          },
          method: 'GET',
        })
        const mappedArray = response.data.map(location => {
          return {
            location: {
              address: location.location.address,
              title: location.location.title,
              id: location.location.id,
              dates: location.dates
            }
          }
        })
        await store.dispatch('getAlbumsLocations', {array: mappedArray})
        localStorage.setItem('albumsLocations', JSON.stringify(mappedArray))
      } catch (e) {
        console.log(e)
        if (e.response.data.message === 'Expired JWT Token' || e.response.data.message === 'Invalid credentials.') {
          await store.dispatch('logoutUser')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      } finally {
        await store.dispatch('setIsLoading', false)
      }
    }
  },
};
