import store from "@/store/index";
import axios from "axios";
import router from "@/router";
// ЭТОТ стор содержит локации, в которых у фотографа уже есть загруженные альбомы
export default {
  state: {
    saleLocations: localStorage.getItem('saleLocations') ? JSON.parse(localStorage.getItem('saleLocations')) : [],
  },

  getters: {
    saleLocations: state => state.saleLocations
  },

  mutations: {
    GET_SALE_LOCATIONS(state, array) {
      state.saleLocations = array
    },
  },

  actions: {
    getSaleLocations({commit}, payload) {
      commit('GET_SALE_LOCATIONS', payload.array)
    },
    async fetchSaleLocations() {
      //получаем список всех альбомов загруженных,
      //мапим и вытаскиваем нужные нам параметры(id альбома, дата, id локации)
      //шлем еще один запрос в цикле чтобы вытащить локации по полученным id
      await store.dispatch('setIsLoading', true)
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/photographer/sales_direct_filters',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${store.getters.token}`
          },
          method: 'GET',
        })
        const dataArray = Object.values(response.data);
        const mappedArray = dataArray.map(item => {
          return {
            location: {
              address: item.location.address,
              title: item.location.title,
              id: item.location.id,
              dates: item.dates
            }
          }
        })
        await store.dispatch('getSaleLocations', {array: mappedArray})
        localStorage.setItem('saleLocations', JSON.stringify(mappedArray))
      } catch (e) {
        console.error(e)
        if (e.response.data.message === 'Expired JWT Token' || e.response.data.message === 'Invalid credentials.') {
          await store.dispatch('logoutUser')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      } finally {
        await store.dispatch('setIsLoading', false)
      }
    }
  },
};
