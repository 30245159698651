export default {
  state: {
    alertMessage: '',
    alertType: 'error',
    isAlert: false,
  },

  getters: {
    alertMessage: (state) => state.alertMessage,
    alertType: (state) => state.alertType,
    isAlert: (state) => state.isAlert,
  },

  mutations: {
    CHANGE_ALERT_MESSAGE(state, message) {
      state.alertMessage = message
    },
    CHANGE_ALERT_TYPE(state, type) {
      state.alertType = type
    },
    TOGGLE_IS_ALERT(state, bool) {
      state.isAlert = bool;
    },
  },

  actions: {
    changeAlertMessage({state, commit}, message) {
      commit('CHANGE_ALERT_MESSAGE', message)
    },
    changeAlertType({state, commit}, type) {
      commit('CHANGE_ALERT_TYPE', type)
    },
    setIsAlert({state, commit}, bool) {
      commit('TOGGLE_IS_ALERT', bool)
    },
  },
};
