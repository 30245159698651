<template>
  <div class="view-page">
    <TitlteH1 title="Фото онлайн" class="mb-2 mb-xl-8"/>
    <div class="filtres mb-4 mb-lg-8 mb-xl-10">
      <v-row class="align-center">
        <v-col @click="filterStatus(1)" cols="6" sm="3" lg="3"
               :class="['bordered', 'text-center', {active: tab === 1}]">
          <div class="tab">Все</div>
        </v-col>
        <v-col @click="filterStatus(2)" cols="6" sm="3" lg="3"
               :class="['bordered', 'text-center', {active: tab === 2}]">
          <div class="tab">Загруженные</div>
        </v-col>
        <v-col @click="filterStatus(3)" cols="6" sm="3" lg="3"
               :class="['text-center', 'bordered', {active: tab === 3}]">
          <div class="tab">На модерации</div>
        </v-col>
        <v-col @click="filterStatus(4)" cols="6" sm="3" lg="3"
               :class="['mr-auto', 'text-center', 'bordered', {active: tab === 4}]">
          <div class="tab">Отклоненные</div>
        </v-col>
        <v-col cols="6">
          <v-select
            :items="albumsLocations"
            item-text="location.title"
            item-value="location"
            label="Укажите локацию"
            v-model="location"
            hide-details
            clearable
            @click:clear="clearQuery"
          >
          </v-select>
        </v-col>
        <v-col cols="6" v-if="location">
          <v-dialog
            ref="dialog"
            v-model="datepicker"
            :return-value.sync="date"
            persistent
            width="320px"
            @input="getAlbum"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Укажите дату"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              scrollable
              first-day-of-week="1"
              :allowed-dates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                Ок
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <GalleryBtns status :hasDelete="rejected" :gallery="returnGallery" @delete="removePhoto"/>
    <div
      v-intersect="next"
      class="observer"
    >
    </div>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import GalleryStepper from "@/components/gallery/GalleryStepper";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import VGallery from "@/components/gallery/VGallery";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";

export default {
  name: "Photos",
  components: {GalleryBtns, VGallery, GalleryStepper, TitlteH1},
  data() {
    return {
      page: 1,
      gallery: [],
      total: 0,
      date: '',
      location: '',
      datepicker: false,
      tab: 1,
      status: '',
      album: '',
      canLoad: true,
    }
  },
  methods: {
    async removePhoto(id) {
      //Удаление фотографии из загруженных фоток
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/photographer/album_photos/${id}`,
          headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'DELETE',
        })
        this.changeAlertType('success');
        this.setIsAlert(true)
        this.changeAlertMessage('Фотография удалена')
        this.assistant = ''
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
        this.total--
        this.gallery = this.gallery.filter(item => item.id !== id)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        this.canLoad = false
        this.page++;
        await this.getAllPhotos()
      }
    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
            Загружаю фото с сервера...
           </span>`
      );

      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;

        }
        this.total = response.data['hydra:totalItems'];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async getStatusPhoto() {
      // Получаем новый массив фоток
      //Используется при фильтре по типу фото
      //А так же при фильтре по альбому в выпадашках и дате
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
            Загружаю фото с сервера...
           </span>`
      );

      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.total = response.data['hydra:totalItems'];
        this.gallery = [...response.data['hydra:member']];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async filterStatus(num) {
      this.page = 1;
      this.gallery = [];
      this.tab = num;
      switch (num) {
        case 1:
          this.status = ''
          break
        case 2:
          this.status = 'approved'
          break
        case 3:
          this.status = 'waiting'
          break
        case 4:
          this.status = 'rejected'
          break
        default:
          this.status = ''
          break
      }
      await this.getStatusPhoto()
    },
    allowedDates(val) {
      return this.formattingDates.includes(val);
    },
    async getAlbum(e) {
      //Запрос альбома после закрытия попапа с датой.
      // лучше события не нашел, остальные не отрабатывают как надо
      // сравниваю полученную в календаре дату со всеми локациями и вытаскиваю второй параметр-id альбома
      //записываю его в переменную, в baseUrl добавляетсястрока и совершаю запрос за фотками
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.canLoad = true;
      if (e === false) {
        this.location.dates.forEach(date => {
          if (date[0].includes(this.date)) {
            this.album = date[1]
          }
        })
        await this.getStatusPhoto()
      }
    },
    async clearQuery() {
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.date = '';
      this.location = '';
      this.datepicker = false;
      this.tab = 1;
      this.status = '';
      this.album = '';
      this.canLoad = true;
      await this.getAllPhotos()
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'fetchAlbumsLocations'])
  },
  computed: {
    rejected() {
      return this.status === 'rejected';
    },
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    returnGallery() {
      return this.gallery
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },
    formattingDates() {
      const dates = [];
      if (this.location.dates) {
        this.location.dates.forEach(date => {
          dates.push(date[0].slice(0, 10));
        })
      }
      return dates;
    },
    baseUrl() {
      let url = `${(this.URL)}/api/photographer/album_photos?page=${(this.page)}&itemsPerPage=24&order%5BcreatedAt%5D=desc`
      if (this.status) {
        url += `&status=${this.status}`
      }
      if (this.album) {
        url += `&album.id=${this.album}`
      }

      return url
    },
    ...mapGetters(['URL', 'token', 'albumsLocations'])
  },
  async mounted() {
    await this.getAllPhotos()
    await this.fetchAlbumsLocations()
  }
}
</script>

<style lang="scss" scoped>
.bordered {
  border-bottom: 1px solid #9c9c9c;
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    font-weight: 700;
    color: #4442D2;
  }
}
</style>
