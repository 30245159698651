<template>
  <div class="view-page">
    <TitlteH1 title="Продажи на месте" class="mb-2 mb-xl-8"/>
    <div class="filtres mb-4 mb-lg-8 mb-xl-10">
      <v-row class="align-center">
        <v-col cols="6" lg="3">
          <v-select
            :items="saleLocations"
            item-text="location.title"
            item-value="location"
            label="Укажите локацию"
            v-model="location"
            hide-details
            clearable
            @click:clear="clearQuery"
          >
          </v-select>
        </v-col>
        <v-col cols="6" lg="3" v-if="location">
          <v-dialog
            ref="dialog"
            v-model="datepicker"
            :return-value.sync="date"
            persistent
            width="320px"
            @input="getAlbum"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Укажите дату"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              scrollable
              first-day-of-week="1"
              :allowed-dates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                Ок
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <GalleryBtns @download="downloadImg" @reUploadImg="reUploadImg" reUpload status price :gallery="returnGallery"/>
    <div
      v-intersect="next"
      class="observer"
    >
    </div>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import GalleryStepper from "@/components/gallery/GalleryStepper";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {add, format} from 'date-fns'
import VGallery from "@/components/gallery/VGallery";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";
import saleLocations from "@/store/user/saleLocations";

export default {
  name: "Deals",
  components: {GalleryBtns, VGallery, GalleryStepper, TitlteH1},
  data() {
    return {
      page: 1,
      gallery: [],
      total: 0,
      date: '',
      location: '',
      datepicker: false,
      tab: 1,
      status: '',
      album: '',
      canLoad: true,
    }
  },
  methods: {
    reUploadImg(img) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file'); //or any other extension
      document.body.appendChild(input);
      input.click();
      input.addEventListener('change', async () => {
        const formData = new FormData();
        formData.append('file', input.files[0])
        formData.append('id', img.id)
        await axios({
          baseURL: this.URL + '/api/photographer/sales_direct_photos/update',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'POST',
          data: formData,
        })
        await this.getStatusPhoto()
      })
      document.body.removeChild(input);
    },
    downloadImg(img) {
      const link = document.createElement('a');
      link.href = this.URL + img.full;
      link.setAttribute('download', img.filename); //or any other extension
      link.setAttribute('target', '_blank'); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        this.canLoad = false
        this.page++;
        await this.getAllPhotos()
      }
    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
            Загружаю фото с сервера...
           </span>`
      );

      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;

        }
        this.total = response.data['hydra:totalItems'];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async getStatusPhoto() {
      // Получаем новый массив фоток
      //Используется при фильтре по типу фото
      //А так же при фильтре по альбому в выпадашках и дате
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
            Загружаю фото с сервера...
           </span>`
      );

      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.total = response.data['hydra:totalItems'];
        this.gallery = [...response.data['hydra:member']];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    allowedDates(val) {
      return this.formattingDates.includes(val);
    },
    async getAlbum(e) {
      //Запрос альбома после закрытия попапа с датой.
      // лучше события не нашел, остальные не отрабатывают как надо
      // сравниваю полученную в календаре дату со всеми локациями и вытаскиваю второй параметр-id альбома
      //записываю его в переменную, в baseUrl добавляетсястрока и совершаю запрос за фотками
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.canLoad = true;
      if (e === false) {
        // this.location.dates.forEach(date => {
        //   if (date[0].includes(this.date)) {
        //     this.album = date[1]
        //   }
        // })
        await this.getStatusPhoto()
      }
    },
    async clearQuery() {
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.date = '';
      this.location = '';
      this.datepicker = false;
      this.tab = 1;
      this.status = '';
      this.album = '';
      await this.getAllPhotos()
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'fetchSaleLocations'])
  },
  computed: {
    saleLocations() {
      console.log(saleLocations);
      return saleLocations
    },
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    returnGallery() {
      return this.gallery
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },
    formattingDates() {
      const dates = [];
      if (this.location.dates) {
        this.location.dates.forEach(date => {
          dates.push(date[0].slice(0, 10));
        })
      }
      return dates;
    },
    baseUrl() {
      let url = `${(this.URL)}/api/photographer/sales_direct_photos?page=${(this.page)}&itemsPerPage=24`
      if (this.date) {
        //получаем дату до и после выбранной для запроса
        const before = format(add(new Date(this.date), {days: -1}), 'yyyy-MM-dd');
        const after = format(add(new Date(this.date), {days: 1}), 'yyyy-MM-dd');

        url += `&salesDirect.dateAt%5Bstrictly_before%5D=${after}&salesDirect.dateAt%5Bstrictly_after%5D=${before}`
      }
      if (this.location) {
        url += `&salesDirect.id=${this.location.id}`
      }

      return url
    },
    ...mapGetters(['URL', 'token', 'saleLocations'])
  },
  async mounted() {
    const {location, date} = this.$route.query
    await this.fetchSaleLocations()

    if (location && date) {
      //
      this.location = this.saleLocations.filter(item => item.location.id === +location)[0].location
      this.date = date
      await this.getStatusPhoto()
    } else {
      await this.getAllPhotos()
    }
  },
}
</script>

<style lang="scss" scoped>
.bordered {
  border-bottom: 1px solid #9c9c9c;
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    font-weight: 700;
    color: #4442D2;
  }
}
</style>
