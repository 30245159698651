<template>
  <div class="view-page">
    <TitlteH1 title="История начислений" class="mb-2 mb-xl-8"/>
    <v-data-table
      :headers="headers"
      :items="balanceHistory['hydra:member']"
      :search="search"
      :items-per-page.sync="itemsPerPage"
      :page.sync="currentPage"
      :server-items-length="balanceHistory['hydra:totalItems']"
      :footer-props="{
        itemsPerPageOptions: [5, 7, 10],
        showCurrentPage: true,
        showFirstLastPage: true
      }"
      class="elevation-1"
      density="compact"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td :class="{ 'positive-value': item.amount > 0, 'negative-value': item.amount < 0 }">{{ item.amount }}</td>
          <td>{{ item.amountBefore }}</td>
          <td>{{ item.amountAfter }}</td>
          <td>{{ item.description }}</td>
          <td>
            <!-- Проверяем наличие фотографии -->
            <template v-if="item.photo && item.photo.full">
              <img
                :src="`${store.getters.URL}${item.photo.full}`"
                alt="Photo"
                @click="showPhoto(item.photo.full)"
                style="cursor: pointer; max-width: 100px; max-height: 100px;"
              />
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="75wv" overlay-opacity="0.99">
      <v-img :src="selectedPhotoUrl" max-height="75vh" contain></v-img>
      <v-btn class="mt-3" @click="closeDialog">Закрыть</v-btn>
    </v-dialog>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import axios from "axios";
import store from "@/store";
import {mapGetters} from "vuex";

export default {
  name: "history",
  components: {TitlteH1},
  data() {
    return {
      balanceHistory: {},
      currentPage: 1,
      itemsPerPage: 5,
      search: '',
      headers: [
        {text: 'Начисление', value: 'amount'},
        {text: 'Баланс до', value: 'amountBefore', sortable: false},
        {text: 'Баланс после', value: 'amountAfter', sortable: false},
        {text: 'Примечание', value: 'description', sortable: false},
        {text: "", value: "photo", sortable: false},
      ],
      showDialog: false,
      selectedPhotoUrl: '',
    };
  },
  methods: {
    resetPhotoData() {
      this.selectedPhotoUrl = '';
      this.showDialog = false;
    },
    fetchData() {
      axios
        .get(`${store.getters.URL}/api/photographer/balance_history?page=${this.currentPage}&itemsPerPage=${this.itemsPerPage}`, {
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/ld+json'
          }
        })
        .then(response => {
          this.balanceHistory = response.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    showPhoto(photoUrl) {
      this.selectedPhotoUrl = `${store.getters.URL}${photoUrl}`;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
  computed: {
    store() {
      return store
    },
    ...mapGetters(['user', 'URL', 'token']),
  },

  watch: {
    currentPage() {
      this.resetPhotoData();
      this.fetchData();
    },
    itemsPerPage() {
      this.resetPhotoData();
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped lang="scss">
.positive-value {
  color: green;
}

.negative-value {
  color: red;
}
</style>
